
export default {
  setAccessToken(state, val) {
    state.token = val;
  },
  setRefreshToken(state, val) {
    state.refreshToken = val;
  },
  setSession(state, val) {
    state.sessionData = val;
  },
  setLoginErrors(state, val) {
    state.loginErrors = val;
  },
  setErrors(state, val) {
    state.errors = val;
  },
  setTokenExpiryStatus(state, val) {
    state.tokenExpired = val;
  },
  setSessionRefreshed(state, val) {
    state.sessionRefreshed = val;
  },
  setDrawerStatus( state, val) {
    state.drawerStatus = val;
  },
};
