import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

Vue.use(Vuex);

const state = {
  token: localStorage.getItem('jwtToken'),
  authenticationStatus: null,
  sessionData: null,
  refreshToken: null,
  loginErrors: null,
  tokenExpired: false,
  sessionRefreshed: false,
  errors: {},
  drawerStatus: true,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
