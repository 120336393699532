const colorMixin = {
    data() {
      return {
        sendyBlue: '#527CBD',
        sendyBlue10: '#D3DDF6',
        sendyMidnightBlue10: '#D3DDF6',
        sendyMidnightBlue20: '#324BA8',
        sendyAzureTransporter30: '#0062DB',
        sendyGray20: '#F7F9FC',
        sendyGray30: '#F0F3F7',
        sendyGray80: '#606266',
        sendyGray40: '#E2E7ED',
        sendyGrey1: '#F7f8fD',
        black: 'black',
        grey: 'grey',
  
        sendyOrange: '#f17f3a',
  
        sendySuccessLight: '#EDFCE2',
        sendySuccessDark: '#3F9F4A',
        sendydangerLight: '#FFE9D8',
        sendyWarningDark: '#B71F25',
  
      };
    },
  };
  export default colorMixin;
  