import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
 
  {
    path: '/',
    name: 'entryLayout',
    component: () => import('@/modules/Entry/entryLayout'),
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
    children: [
      {
        path: 'new',
        name: 'newEntry',
        component: () => import('@/modules/Entry/newEntry'),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
      },
      {
        path: 'existing',
        name: 'existing',
        component: () => import('@/modules/Entry/entry'),
        meta: {
          requiresAuth: true,
          requiresFilters: true,
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/login'),
  },
  {
    path: '/register',
    name: 'signup',
    component: () => import('@/modules/signup'),
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('@/modules/uploads'),
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/modules/reports'),
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
  },
  {
    path: '/sms',
    name: 'sms',
    component: () => import('@/modules/sms'),
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
  },
  {
    path: '/limited',
    name: 'limited',
    component: () => import('@/modules/limited'),
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
  },
  {
    path: '/user-management',
    name: 'userManagement',
    component: () => import('@/modules/userManagement'),
    meta: {
      requiresAuth: true,
      requiresFilters: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch('initAuth');
    if (store.getters.isAuthenticated) {
      if (to.path === '/'){
        next('/new')
        return;
      }
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router
