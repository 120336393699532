import { Base64 } from 'js-base64';
import router from '@/router';
import axios from 'axios';

export default {
  async initAuth({ commit, state, dispatch }) {
    const token = localStorage.getItem('jwtToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const tokenExpiryStatus = state.tokenExpired;

    if (tokenExpiryStatus) {
      await dispatch('handleErrors', 403, {
        root: true,
      });
      return;
    }

    if (token !== null && token !== '') {
      const partsOfToken = token.split('.');

      const middleString = Base64.decode(partsOfToken[1]);
      const payload = JSON.parse(middleString);
      const userData = payload.data
      commit('setSession', userData);
    }
    commit('setAccessToken', token);
    commit('setRefreshToken', refreshToken);
  },

  clearCache({ commit }) {
    commit('setAccessToken', null);
    commit('setRefreshToken', null);
    localStorage.clear();
  },
  async handleErrors({ commit, dispatch }, error) {
    switch (error) {
      case 403:
        await dispatch('refreshAuthToken');
        break;
      case 204:
        commit('setTokenExpiryStatus', true);
        commit('setLoginErrors', ' You have been successfully logged out.');
        await dispatch('clearCache');
        router.push('/login');
        break;
      default:
    }
  },
  async custom_headers({ state }, fileUpload) {
    const authToken = state.token;

    const param = {
      headers: {
        'Content-Type': fileUpload ? 'multipart/form-data' : 'application/json',
        Accept: 'application/json',
        Authorization: authToken,
      },
    };

    return param;
  },
  setErrorAction({ commit }, payload) {
    let errors = {};
    payload.forEach(el => {
      errors[el.field] = el.message;
    });
    commit("setErrors", errors);
  },
  async axiosPostRequest({ dispatch }, payload) {
    const { endpoint } = payload;
    const { params } = payload;
    const { fileUpload } = payload;
    // eslint-disable-next-line no-underscore-dangle
    const headers = await dispatch('custom_headers', fileUpload);
    const url = `${process.env.VUE_APP_AUTH_URL}${endpoint}`;
  
    try {
      const response = await axios.post(url, params, headers);
      return response;
    } 
    catch (error) {
      await dispatch('setErrorAction', error.response.data);
      await dispatch('handleErrors', error.response.status, {
        root: true,
      });
      return error.response;
    }
  },
  async axiosPutRequest({ dispatch }, payload) {
    const { endpoint } = payload;
    const { params } = payload;
    const { fileUpload } = payload;
    // eslint-disable-next-line no-underscore-dangle
    const headers = await dispatch('custom_headers', fileUpload);
    const url = `${process.env.VUE_APP_AUTH_URL}${endpoint}`;
  
    try {
      const response = await axios.put(url, params, headers);
      return response;
    } 
    catch (error) {
      await dispatch('setErrorAction', error.response.data);
      await dispatch('handleErrors', error.response.status, {
        root: true,
      });
      return error.response;
    }
  },
  async axiosGetRequest({ dispatch }, payload) {
    const headers = await dispatch('custom_headers');
    const { endpoint } = payload;
    const { params } = payload;

    let url = `${process.env.VUE_APP_AUTH_URL}${endpoint}`;

    const values = {
      params,
      headers: headers.headers,
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const value in values) {
      if (values[value] === null || values[value] === undefined) {
        delete values[value];
      }
    }

    try {
      const response = await axios.get(url, values);
      return response;
    } catch (error) {
      await dispatch('setErrorAction', error.data);
      await dispatch('handleErrors', error.response.status, {
        root: true,
      });
      return error.message;
    }
  },
  async performPostActions({ dispatch }, payload) {
    try {
      const res = await dispatch('axiosPostRequest', payload, { root: true });
      return res.data;
    } catch (error) {
      return error.response;
    }
  },
  async loginPostActions({ dispatch }, payload) {
    try {
      const res = await dispatch('axiosPostRequest', payload, { root: true });
      return res;
    } catch (error) {
      return error.response;
    }
  },
  async performGetActions({ dispatch }, payload) {
    try {
      const res = await dispatch('axiosGetRequest', payload, { root: true });
      return res.data;
    } catch (error) {
      return error.response;
    }
  },
  async performUpdateActions({ dispatch }, payload) {
    try {
      const res = await dispatch('axiosPutRequest', payload, { root: true });
      return res.data;
    } catch (error) {
      return error.response;
    }
  },

};
