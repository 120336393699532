<template>
  <v-app>
    <v-app-bar
      app
      dark
      :color="sendyMidnightBlue20"
      prominent
    >
    <v-app-bar-nav-icon v-if="$route.name !== 'login' && $route.name !== 'signup'" @click="setDrawer()"></v-app-bar-nav-icon>
    <v-toolbar-title class="mx-auto"> Church Registar</v-toolbar-title>
   <v-spacer></v-spacer>

    <span class="mt-2" v-if="$route.name !== 'login' && $route.name !== 'signup'"> {{ getSession ? capitalise(getSession.username): '' }}</span>
    <v-menu offset-y v-if="$route.name !== 'login' && $route.name !== 'signup'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon class=""
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="$router.push('/login')">
          <v-list-item-title @click="logout">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>
    <SideBar v-if="$route.name !== 'login' && $route.name !== 'signup'" />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import colorMxn from '@/mixins/color_mixins';

export default {
  name: 'App',
  mixins: [colorMxn],
  components: {
    SideBar: () => import('@/components/sideBarComponent'),
  },
  data: () => ({
    drawer: true,
    title: '',
  }),
  computed: {
    ...mapGetters(['getDrawerStatus', 'getSession', 'isAuthenticated']),
  },
  watch: {
    getDrawerStatus(status) {
      this.drawer = status;
    },
  },
  methods: {
    ...mapMutations(['setDrawerStatus']),
    ...mapActions(['clearCache']),
    async logout() {
      await this.clearCache();
      this.$router.push('/login');
    },
    setDrawer() {
      this.drawer = !this.drawer;
      this.setDrawerStatus(this.drawer);
    },
    capitalise(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  }
};
</script>

<style>
.v-btn {
  text-transform: none !important;
}
.v-tab {
text-transform: none !important;
}
</style>
